import {MoreInfoInterface, RoomDetailsInterface} from "../interfaces/room-type.interface";

export const ZIP_CODE_REG_EX = new RegExp(/^\d{4} ?[a-z]{2}$/i)

export enum PACKAGES {
  es = "oplever schoonmaak",
  gs = "grote schoonmaak",
  ls = "voorjaar schoonmaak",
  rs = "verbouw schoonmaak",
  vs = "verhuis schoonmaak",
  zs = "zwangerschap schoonmaak",
  ms = 'Schoonmaak per uur'
}

export const defaultRoomDetails: RoomDetailsInterface[] = [
  {
    name: "Keuken",
    details: [
      {
        title: "Plafond / muur",
        info: [
          "spinnenwebben weghalen",
          "afnemen schakelaars en wandcontacten",
          "radiator afnemen en uitsteken",
          "vensterbank/ deuren afnemen",
          "armatuur afnemen",
        ]
      }, {
        title: "Kookplaat / afzuigkap",
        info: [
          "ontvetten en opwrijven afzuigkap",
          "ontvetten luchtfilter in afzuigkap",
          "desinfecteren/ontvetten kookplaat",
        ]
      }, {
        title: "vloer",
        info: [
          "keukenplint afnemen",
          "stofzuigen",
          "dweilen",
        ]
      }, {
        title: "Tegelwerk / werkblad",
        info: [
          "ontvetten/afnemen",
          "wandcontactdoos/armatuur afnemen",
        ]
      }, {
        title: "koelkast",
        info: [
          "binnenzijde desinfecteren",
          "buitenzijde afnemen/opwrijven"
        ]
      }, {
        title: "keukenkasten",
        info: [
          "boven- en onderzijde ontvetten / afnemen",
          "buitenzijde vlekken verwijderen/afnemen",
          "binnenzijde vlekken verwijderen/afnemen is optioneel",

        ]
      }, {
        title: "Oven Magnetron",
        info: [
          "binnenzijde desinfecteren/ontvetten",
          "buitenzijde afnemen/opwrijven",
        ]
      }, {
        title: "Gootsteen / kraan",
        info: [
          "ontkalken/ opwrijven"]
      },
    ],
  }, {
    name: "badkamer",
    details: [
      {
        title: "Plafond / muur",
        info: [
          "spinnenwebben weghalen",
          "afnemen schakelaars en wandcontacten",
          "radiator afnemen en uitsteken",
          "deuren / vensterbank afnemen",
          "armatuur afnemen",
          "ventilator afnemen / uitsteken",
        ]
      },
      {
        title: "Wastafel",
        info: [
          "ontkalken / opwrijven",
          "spiegel vlekken verwijderen / opwrijven",
          "meubel buitenzijde afnemen",
        ]
      },
      {
        title: "Douche / bad",
        info: [
          "douche- en badgarnituur ontkalken / opwrijven",
          "tegelwerk ontkalken / desinfecteren"
        ]
      },
      {
        title: "Toilet",
        info: [
          "binnenzijde ontkalken / desinfecteren",
          "buitenzijde desinfecteren / opwrijven"
        ]
      },
      {
        title: "Vloer",
        info: [
          "plinten afnemen",
          "stofzuigen",
          "dweilen",
        ]
      }
    ]
  }, {
    name: "Woonkamer",
    details: [
      {
        title: "Plafond / muur",
        info: [
          "radiatoren uitsteken en reinigen",
          "spinnenrag van plafond verwijderen",
          "deuren, kozijnen, vensters en plinten nat afnemen",
          "schakelaars, wandcontactdozen en thermostaten reinigen"
        ]
      },
      {
        title: "Vloer",
        info: [
          "plinten afnemen",
          "stofzuigen",
          "dweilen",
        ]
      }
    ]
  }, {
    name: "overige ruimtes",
    details: [
      {
        title: "Plafond / muur",
        info: [
          "spinnenwebben weghalen",
          "afnemen schakelaars en wandcontacten",
          "radiator afnemen en uitsteken",
          "deuren / vensterbank afnemen",
          "armatuur afnemen",
          "ventilator afnemen / uitsteken",
        ]
      },
      {
        title: "Vloer",
        info: [
          "plinten afnemen",
          "stofzuigen",
          "dweilen"
        ]
      }
    ]
  }];
export const roomDetails: MoreInfoInterface[] = [
  {
    packageId: "gs",
    roomDetails: [
      {
        name: "Keuken",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "vensterbank/ deuren afnemen",
              "armatuur afnemen",
            ]
          }, {
            title: "Kookplaat / afzuigkap",
            info: [
              "ontvetten en opwrijven afzuigkap",
              "ontvetten luchtfilter in afzuigkap",
              "desinfecteren/ontvetten kookplaat",
            ]
          }, {
            title: "vloer",
            info: [
              "keukenplint afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }, {
            title: "Tegelwerk / werkblad",
            info: [
              "ontvetten/afnemen",
              "wandcontactdoos/armatuur afnemen",
            ]
          }, {
            title: "koelkast",
            info: [
              "binnenzijde desinfecteren",
              "buitenzijde afnemen/opwrijven"
            ]
          }, {
            title: "keukenkasten",
            info: [
              "boven- en onderzijde ontvetten / afnemen",
              "buitenzijde vlekken verwijderen/afnemen",
              "binnenzijde vlekken verwijderen/afnemen is optioneel",

            ]
          }, {
            title: "Oven Magnetron",
            info: [
              "binnenzijde desinfecteren/ontvetten",
              "buitenzijde afnemen/opwrijven",
            ]
          }, {
            title: "Gootsteen / kraan",
            info: [
              "ontkalken/ opwrijven"]
          },
        ],
      }, {
        name: "badkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren / vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen / uitsteken",
            ]
          },
          {
            title: "Wastafel",
            info: [
              "ontkalken / opwrijven",
              "spiegel vlekken verwijderen / opwrijven",
              "meubel buitenzijde afnemen",
            ]
          },
          {
            title: "Douche / bad",
            info: [
              "douche- en badgarnituur ontkalken / opwrijven",
              "tegelwerk ontkalken / desinfecteren"
            ]
          },
          {
            title: "Toilet",
            info: [
              "binnenzijde ontkalken / desinfecteren",
              "buitenzijde desinfecteren / opwrijven"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },
      {
        name: "overige ruimtes",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren / vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen / uitsteken",
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen"
            ]
          }
        ]
      },
      {
        name: "Woonkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "Radiatoren uitsteken en reinigen",
              "Spinnenrag van plafond verwijderen",
              "Deuren, kozijnen, vensters en plinten nat afnemen",
              "Schakelaars, wandcontactdozen en thermostaten reinigen"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen"
            ]
          }
        ]
      },
    ]
  }, {
    packageId: "vs",
    roomDetails: [
      {
        name: "keuken",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "vensterbank/ deuren afnemen",
              "armatuur afnemen",
            ]
          }, {
            title: "Kookplaat / afzuigkap",
            info: [
              "ontvetten en opwrijven afzuigkap",
              "ontvetten luchtfilter in afzuigkap",
              "desinfecteren/ontvetten kookplaat",
            ]
          }, {
            title: "vloer",
            info: [
              "keukenplint afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }, {
            title: "Tegelwerk / werkblad",
            info: [
              "ontvetten/afnemen",
              "wandcontactdoos/armatuur afnemen",
            ]
          }, {
            title: "koelkast",
            info: [
              "binnenzijde desinfecteren",
              "buitenzijde afnemen/opwrijven"
            ]
          }, {
            title: "keukenkasten",
            info: [
              "boven- en onderzijde ontvetten / afnemen",
              "buitenzijde vlekken verwijderen/afnemen",
              "binnenzijde vlekken verwijderen/afnemen is optioneel",

            ]
          }, {
            title: "Oven Magnetron",
            info: [
              "binnenzijde desinfecteren/ontvetten",
              "buitenzijde afnemen/opwrijven",
            ]
          }, {
            title: "Gootsteen / kraan",
            info: [
              "ontkalken/ opwrijven"]
          },
        ],
      },
      {
        name: "badkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren / vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen / uitsteken",
            ]
          },
          {
            title: "Wastafel",
            info: [
              "ontkalken / opwrijven",
              "spiegel vlekken verwijderen / opwrijven",
              "meubel buitenzijde afnemen",
            ]
          },
          {
            title: "Douche / bad",
            info: [
              "douche- en bad garnituur ontkalken / opwrijven",
              "tegelwerk ontkalken / desinfecteren"
            ]
          },
          {
            title: "Toilet",
            info: [
              "binnenzijde ontkalken / desinfecteren",
              "buitenzijde desinfecteren / opwrijven"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },
      {
        name: "overige ruimtes",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren / vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen / uitsteken",
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen"
            ]
          }
        ]
      },
      {
        name: "woonkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenrag van plafond verwijderen",
              "deuren, kozijnen, vensters en plinten stucresten verwijderen en nat afnemen",
              "schakelaars, wandcontactdozen en thermostaten reinigen",
              "Radiatoren reinigen en uitsteken",
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },
    ]
  }, {
    packageId: "es",
      roomDetails: [
        {
          name: "Keuken",
          details: [
            {
              title: "Plafond / muur",
              info: [
                "plafond nalopen op spinnenwebben",
                "afnemen schakelaars en wandcontacten",
                "radiator afnemen en uitsteken",
                "vensterbank/ Deuren afnemen",
                "armatuur afnemen"
              ]
            }, {
              title: "Kookplaat / afzuigkap",
              info: [
                "ontvetten en opwrijven afzuigkap",
                "ontvetten luchtfilter in afzuigkap",
                "desinfecteren/ontvetten kookplaat",
                "R.V.S reinigen en opwrijven",
              ]
            }, {
              title: "vloer",
              info: [
                "keukenplint afnemen",
                "stofzuigen",
                "dweilen",
              ]
            }, {
              title: "Tegelwerk / werkblad",
              info: [
                "ontvetten/afnemen",
                "wandcontactdoos/armatuur afnemen",
                "ontkalken van het werkblad, gootsteen en kranen",
              ]
            }, {
              title: "koelkast",
              info: [
                "binnenzijde desinfecteren",
                "buitenzijde afnemen/opwrijven"
              ]
            }, {
              title: "keukenkasten",
              info: [
                "boven- en onderzijde ontvetten / afnemen",
                "buitenzijde vlekken verwijderen/afnemen",
                "binnenzijde vlekken verwijderen/afnemen",

              ]
            }, {
              title: "Oven / Magnetron",
              info: [
                "desinfecteren/ontvetten",
                "buitenzijde afnemen/opwrijven",
              ]
            }, {
              title: "Gootsteen / kraan",
              info: [
                "ontkalken/ opwrijven"]
            },
            {
              title: "Vaatwasser",
              info: [
                "vaatwasser binnen en buitenzijde reinigen",
                "filters uitnemen en uitspoelen"
              ]
            },
          ],
        }, {
          name: "badkamer",
          details: [
            {
              title: "Plafond / muur",
              info: [
                "spinnenwebben weghalen",
                "afnemen schakelaars en wandcontacten",
                "radiator afnemen en uitsteken",
                "deuren/vensterbanken afnemen",
                "armatuur afnemen",
                "ventilator afnemen/uitsteken",
                "ontkalken van de tegels",
                "afnemen binnen- en buitenzijde kasten",
                "armatuur afnemen"
              ]
            },
            {
              title: "Wastafel",
              info: [
                "ontkalken / opwrijven",
                "spiegel vlekken verwijderen / opwrijven",
                "meubel buitenzijde afnemen",
              ]
            },
            {
              title: "Douche / bad",
              info: [
                "uitnemen van douche en bad",
                "ontkalken van douche en bad",
                "douche- en badgarnituur ontkalken/opwrijven"
              ]
            },
            {
              title: "Toilet",
              info: [
                "binnenzijde ontkalken / desinfecteren",
                "buitenzijde desinfecteren / opwrijven"
              ]
            },
            {
              title: "Wasdroger",
              info: [
                "filters schoonmaken",
                "afnemen"
              ]
            },
            {
              title: "Vloer",
              info: [
                "plinten afnemen",
                "ontkalken van de vloer",
                "stofzuigen",
                "dweilen",
              ]
            }
          ]
        }, {
          name: "Woonkamer",
          details: [
            {
              title: "Plafond / muur",
              info: [
                "spinnenwebben weghalen",
                "afnemen schakelaars en wandcontacten",
                "radiator afnemen en uitsteken",
                "deuren, plinten en verwarmingsbuizen afnemen",
                "bovenop kasten afnemen",
                "lege kasten binnenzijde afnemen",
                "verwarmingen en vensterbanken afnemen",
                "opwrijven van spiegels",
                "armatuur afnemen"
              ]
            },
            {
              title: "Meubilair",
              info: [
                "banken en stoelen stofzuigen",
                "tafels vochtig afnemen",
                "dressoir afstoffen",
                "televisie afnemen"
              ]
            },
            {
              title: "Vloer",
              info: [
                "plinten afnemen",
                "stofzuigen",
                "dweilen",
              ]
            }
          ]
        }, {
          name: "Slaapkamer",
          details: [
            {
              title: "Plafond / muur",
              info: [
                "spinnenwebben weghalen",
                "afnemen schakelaars en wandcontacten",
                "radiator afnemen en uitsteken",
                "deuren, plinten en verwarmingsbuizen afnemen",
                "verwarmingen en vensterbanken afnemen",
                "opwrijven van spiegels"
              ]
            },
            {
              title: "Meubilair",
              info: [
                "lege kasten binnen- en buitenzijde afnemen",
                "dressoir afstoffen",
                "stofzuigen onder het bed",
                "hoofdbord bed stofzuigen",
                "bovenop kasten afnemen"
              ]
            },
            {
              title: "Vloer",
              info: [
                "plinten afnemen",
                "stofzuigen",
                "dweilen",
              ]
            }
          ]
        }
        , {
          name: "overige ruimtes",
          details: [
            {
              title: "Plafond / muur",
              info: [
                "spinnenwebben weghalen",
                "afnemen schakelaars en wandcontacten",
                "radiator afnemen en uitsteken",
                "deuren, plinten en verwarmingsbuizen afnemen",
                "bovenop kasten afnemen",
                "lege kasten binnenzijde afnemen",
                "verwarmingen en vensterbanken afnemen",
                "opwrijven van spiegels",
                "armatuur afnemen"
              ]
            },
            {
              title: "Vloer",
              info: [
                "plinten afnemen",
                "stofzuigen",
                "dweilen"
              ]
            }
          ]
        }]
  }, {
    packageId: "rs",
    roomDetails: [
      {
        name: "Keuken",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "nalopen op bouwstof en stucresten",
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "vensterbank/ deuren afnemen",
              "armatuur afnemen",
            ]
          }, {
            title: "Kookplaat / afzuigkap",
            info: [
              "nalopen op bouwstof en stucresten",
              "ontvetten en opwrijven afzuigkap",
              "ontvetten luchtfilter in afzuigkap",
              "desinfecteren/ontvetten kookplaat",
            ]
          }, {
            title: "vloer",
            info: [
              "nalopen op bouwstof en stucresten",
              "keukenplint afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }, {
            title: "Tegelwerk / werkblad",
            info: [
              "nalopen op bouwstof en stucresten",
              "ontvetten/afnemen",
              "wandcontactdoos/armatuur afnemen",
            ]
          }, {
            title: "koelkast",
            info: [
              "nalopen op bouwstof en stucresten",
              "binnenzijde desinfecteren",
              "buitenzijde afnemen/opwrijven"
            ]
          }, {
            title: "keukenkasten",
            info: [
              "nalopen op bouwstof en stucresten",
              "boven- en onderzijde ontvetten / afnemen",
              "buitenzijde vlekken verwijderen/afnemen",
              "binnenzijde vlekken verwijderen/afnemen is optioneel",

            ]
          }, {
            title: "oven magnetron",
            info: [
              "binnenzijde desinfecteren/ontvetten",
              "buitenzijde afnemen/opwrijven",
            ]
          }, {
            title: "Gootsteen / kraan",
            info: [
              "nalopen op bouwstof en stucresten",
              "ontkalken/ opwrijven"]
          },
        ],
      },
      {
        name: "badkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "nalopen op bouwstof en stucresten",
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren / vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen / uitsteken",
            ]
          },
          {
            title: "Wastafel",
            info: [
              "nalopen op bouwstof en stucresten",
              "ontkalken / opwrijven",
              "spiegel vlekken verwijderen / opwrijven",
              "meubel buitenzijde afnemen",
            ]
          },
          {
            title: "Douche / bad",
            info: [
              "nalopen op bouwstof en stucresten",
              "douche- en badgarnituur ontkalken / opwrijven",
              "tegelwerk ontkalken / desinfecteren"
            ]
          },
          {
            title: "Toilet",
            info: [
              "nalopen op bouwstof en stucresten",
              "binnenzijde ontkalken / desinfecteren",
              "buitenzijde desinfecteren / opwrijven"
            ]
          },
          {
            title: "Vloer",
            info: [
              "nalopen op bouwstof en stucresten",
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },
      {
        name: "overige ruimtes",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "nalopen op bouwstof en stucresten",
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren / vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen / uitsteken",
            ]
          },
          {
            title: "Vloer",
            info: [
              "nalopen op bouwstof en stucresten",
              "plinten afnemen",
              "stofzuigen",
              "dweilen"
            ]
          }
        ]
      },
      {
        name: "Woonkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "nalopen op bouwstof en stucresten",
              "spinnenrag van plafond verwijderen ",
              "alle objecten worden eerst nagelopen op bouwstof, stucresten en stickers",
              "schakelaars, wandcontactdozen en thermostaten reinigen",
              "radiatoren reinigen en uitsteken ",
              "deuren, kozijnen, vensters en plinten stucresten verwijderen en nat afnemen "
            ]
          },
          {
            title: "Vloer",
            info: [
              "nalopen op bouwstof en stucresten",
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },]
  }, {
    packageId: "ls",
    roomDetails: [
      {
        name: "Keuken",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "vensterbank/ deuren afnemen",
              "armatuur afnemen",
            ]
          },
          {
            title: "Kookplaat / afzuigkap",
            info: [
              "ontvetten en opwrijven afzuigkap",
              "ontvetten luchtfilter in afzuigkap",
              "desinfecteren/ontvetten kookplaat",
            ]
          },
          {
            title: "vloer",
            info: [
              "keukenplint afnemen",
              "stofzuigen",
              "dweilen",
            ]
          },
          {
            title: "koelkast",
            info: [
              "binnenzijde desinfecteren",
              "buitenzijde afnemen/opwrijven"
            ]
          },
          {
            title: "Tegelwerk / werkblad",
            info: [
              "ontvetten/afnemen",
              "wandcontactdoos/ armatuur afnemen"
            ]
          },
          {
            title: "keukenkasten",
            info: [
              "boven- en onderzijde ontvetten / afnemen",
              "buitenzijde vlekken verwijderen/afnemen",
              "binnenzijde vlekken verwijderen/afnemen is optioneel",
            ]
          },
          {
            title: "Oven Magnetron",
            info: [
              "binnenzijde desinfecteren/ontvetten",
              "buitenzijde afnemen/opwrijven"
            ]
          },
          {
            title: "Gootsteen / kraan",
            info: ["ontkalken/ opwrijven"]
          },
        ],
      },
      {
        name: "badkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren stucresten verwijderen en afnemen",
              "armatuur afnemen",
              "ventilator afnemen/ uitsteken",
            ]
          },
          {
            title: "Wastafel",
            info: [
              "ontkalken/ opwrijven",
              "— spiegel vlekken verwijde, opwrijven",
              "meubel buitenzijde afnemen"
            ]
          },
          {
            title: "Douche / bad",
            info: [
              "douche- en badgarnituur ontkalken / opwrijven",
              "tegelwerk ontkalken/ desinfecteren"
            ]
          },
          {
            title: "Toilet",
            info: [
              "binnenzijde ontkalken/ desinfecteren",
              "buitenzijde desinfecteren/ opwrijven",
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },
      {
        name: "overige ruimtes",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren/ vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen/ uitsteken"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen"
            ]
          }
        ]
      },
      {
        name: "Woonkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "radiatoren uitsteken en reinigen",
              "spinnenrag van plafond verwijderen",
              "deuren, kozijnen, vensters en plinten nat afnemen",
              "schakelaars, wandcontactdozen en thermostaten reinigen"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },]
  }, {
    packageId: "zs",
    roomDetails: [
      {
        name: "Keuken",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "vensterbank/ deuren afnemen",
              "armatuur afnemen",
            ]
          },
          {
            title: "Kookplaat / afzuigkap",
            info: [
              "ontvetten en opwrijven afzuigkap",
              "ontvetten luchtfilter in afzuigkap",
              "desinfecteren/ontvetten kookplaat"
            ]
          },
          {
            title: "vloer",
            info: [
              "keukenplint afnemen",
              "stofzuigen",
              "dweilen",
            ]
          },
          {
            title: "koelkast",
            info: [
              "binnenzijde desinfecteren",
              "buitenzijde afnemen/opwrijven"
            ]
          },
          {
            title: "Tegelwerk / werkblad",
            info: [
              "ontvetten/afnemen",
              "wandcontactdoos/ armatuur afnemen"
            ]
          },
          {
            title: "keukenkasten",
            info: [
              "boven- en onderzijde ontvetten / afnemen",
              "buitenzijde vlekken verwijderen/afnemen",
              "binnenzijde vlekken verwijderen/afnemen is optioneel",
            ]
          },
          {
            title: "Oven Magnetron",
            info: [
              "binnenzijde desinfecteren/ontvetten",
              "buitenzijde afnemen/opwrijven"
            ]
          },
          {
            title: "Gootsteen / kraan",
            info: ["ontkalken/ opwrijven"]
          },
        ],
      },
      {
        name: "badkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren/ vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen/ uitsteken"
            ]
          },
          {
            title: "Wastafel",
            info: [
              "ontkalken/ opwrijven",
              "— spiegel vlekken verwijde, opwrijven",
              "meubel buitenzijde afnemen"
            ]
          },
          {
            title: "Douche / bad",
            info: [
              "douche- en badgarnituur ontkalken / opwrijven",
              "tegelwerk ontkalken/ desinfecteren"
            ]
          },
          {
            title: "Toilet",
            info: [
              "binnenzijde ontkalken/ desinfecteren",
              "buitenzijde desinfecteren/ opwrijven",
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      },
      {
        name: "overige ruimtes",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "spinnenwebben weghalen",
              "afnemen schakelaars en wandcontacten",
              "radiator afnemen en uitsteken",
              "deuren/ vensterbank afnemen",
              "armatuur afnemen",
              "ventilator afnemen/ uitsteken"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen"
            ]
          }
        ]
      },
      {
        name: "woonkamer",
        details: [
          {
            title: "Plafond / muur",
            info: [
              "deuren, kozijnen, vensters en plinten nat afnemen",
              "radiatoren uitsteken en reinigen",
              "schakelaars, wandcontactdozen en thermostaten reinigen",
              "spinnenrag van plafond verwijderen"
            ]
          },
          {
            title: "Vloer",
            info: [
              "plinten afnemen",
              "stofzuigen",
              "dweilen",
            ]
          }
        ]
      }]
  },
];
