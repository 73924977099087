import {EnvironmentInterface} from "../app/shared/interfaces/environment.interface";

// DEVELOPMENT
// export const environment: EnvironmentInterface = {
  // reviewTrustPilotsUrl: 'https://nl.trustpilot.com/evaluate/schoonmaakwoede.nl',
  // reviewGoogleUrl: 'https://www.google.com/maps/place/Schoonmaakwoede/@52.3924623,4.8502933,17z/data=!4m8!3m7!1s0x47c5e2897f644b1b:0xecb64f9b0d77ea6c!8m2!3d52.3924591!4d4.8528682!9m1!1b1!16s%2Fg%2F11g9qh7rjq?hl=nl&entry=ttu',
  // firebaseConfig: {
  //   projectId: "dev-sw-nl",
  //   appId: "1:853564938304:web:604a7db857a31ff9f367a5",
  //   storageBucket: "dev-sw-nl.appspot.com",
  //   locationId: "europe-west",
  //   apiKey: "AIzaSyCcKrf_2W89oXA6NBZecaJWiDzbwTcKH-Q",
  //   authDomain: "dev-sw-nl.firebaseapp.com",
  //   messagingSenderId: "853564938304",
  //   measurementId: "G-BE9R0HTJG1",
  // },
  // production: false,
  // releaseVersion: '00.00.11',
  // name: "DEV",
  // apiUrl: "https://us-central1-dev-sw-nl.cloudfunctions.net",
  // // apiUrl: "http://127.0.0.1:5001/dev-sw-nl/us-central1",
  // // redirectUrl: "http://localhost:4201/",
  // redirectUrl: "https://dv-sw-order.web.app/",
  // baseUrl: "https://dev.schoonmaakwoede.nl",
  //   localeRedirectUrl: 'https://staging-sw-eng-6b3r7qvmaa-ew.a.run.app'
// };


// STAGING

// export const environment: EnvironmentInterface = {
//   reviewTrustPilotsUrl: "https://nl.trustpilot.com/evaluate/schoonmaakwoede.nl",
//   reviewGoogleUrl: "https://g.page/r/CWzqdw2bT7bsEB0/review",
//   firebaseConfig: {
//     apiKey: "AIzaSyCIttoVYSH8MU2a38YFcy9T2chDaw4VIZY",
//     authDomain: "schoonmaakwoede-staging.firebaseapp.com",
//     projectId: "schoonmaakwoede-staging",
//     storageBucket: "schoonmaakwoede-staging.appspot.com",
//     messagingSenderId: "679903824928",
//     appId: "1:679903824928:web:266d4d96cc6479c88c8045",
//     measurementId: "G-MJQNJCHP3Q",
//     locationId: "europe-west",
//   },
//   production: false,
//   name: "Staging",
//   releaseVersion: "00.01.01",
//   apiUrl: " https://europe-west1-schoonmaakwoede-staging.cloudfunctions.net",
//   redirectUrl: "https://sw-staging-orders.web.app/",
//   baseUrl: "https://staging.schoonmaakwoede.nl",
//   localeRedirectUrl: 'https://staging-sw-eng-6b3r7qvmaa-ew.a.run.app'
// };




// PRODUCTION

export const environment: EnvironmentInterface = {
  reviewTrustPilotsUrl: "https://nl.trustpilot.com/evaluate/schoonmaakwoede.nl",
  reviewGoogleUrl: "https://g.page/r/CWzqdw2bT7bsEB0/review",
  firebaseConfig: {
    apiKey: "AIzaSyCW4MIs_rQvNSQC5ffFMINdxW1q9V2Pz-Q",
    authDomain: "schoonmaakwoede-productie.firebaseapp.com",
    projectId: "schoonmaakwoede-productie",
    storageBucket: "schoonmaakwoede-productie.appspot.com",
    messagingSenderId: "818142803399",
    appId: "1:818142803399:web:72d96561fdb04180fc95e2",
    measurementId: "G-BJXQ9VH6BR",
    locationId: "europe-west",
  },
  production: true,
  name: "Production",
  releaseVersion: "00.03.03",
  apiUrl: "https://europe-west1-schoonmaakwoede-productie.cloudfunctions.net",
  redirectUrl: "https://pakket.schoonmaakwoede.nl/",
  baseUrl: "https://schoonmaakwoede.nl",
  localeRedirectUrl: 'https://english.schoonmaakwoede.nl'
};
